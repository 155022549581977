import httpClient from './httpClient';

const RESOURCE = 'global-variables';

export default {
  getGlobalVariables() {
    return httpClient.get(`/${RESOURCE}`);
  },

  updateGlobalVariables(variables) {
    return httpClient.post(`/${RESOURCE}`, {
      variables: variables,
    });
  },

  downloadSession(startTime, endTime) {
    return httpClient.get(`/${RESOURCE}/download-session`, {
      startTime: startTime,
      endTime: endTime,
    });
  },
};
