<template>
  <form class="md-layout lims-form globalVariables-form">
    <modal-confirm-generic
      ref="unSaveChangesModal"
      :description="$t('components/lims/modals/ModalFormCancel.text')"
      @onConfirm="onUnSaveChanges"
    />
    <lims-block>
      <h4 class="title" slot="blockTitle">{{ $t('pages/GlobalVariables/variables/blockTitle') }}</h4>
      <div slot="blockContent">
        <md-table v-model="variables" md-card class="tbl-variables">
          <md-table-row
            slot="md-table-row"
            slot-scope="{ item }"
            v-if="
              item.keyId !== 'TwilioAccountId' && item.keyId !== 'TwilioAuthToken' && item.keyId !== 'TwilioServiceId'
            "
          >
            <md-table-cell md-label="Category" width="10%">{{ item.categoryName }}</md-table-cell>
            <md-table-cell md-label="Key" width="20%">{{ item.keyName }}</md-table-cell>
            <md-table-cell md-label="Value" width="350">
              <template v-if="item.keyId === 'Country'">
                <v-select v-model="item.keyValue" :options="countries" label="text" tabenable="yes" :tabindex="5">
                  <template #option="{ text }">{{ text }}</template>
                  <template #selected-option="{ text }">{{ text }}</template>
                </v-select>
              </template>
              <template v-else-if="item.keyId === 'Timezone'">
                <v-select
                  v-model="item.keyValue"
                  :options="timeZoneList"
                  label="fieldItemName"
                  class="wrap-long-items"
                  tabenable="yes"
                  :tabindex="5"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </v-select>
              </template>
              <template v-else-if="item.keyId === 'MFARequired'">
                <v-select
                  v-model="item.keyValue"
                  :options="[
                    { label: 'Yes', value: '1' },
                    { label: 'No', value: '2' },
                  ]"
                  tabenable="yes"
                  :tabindex="5"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </template>
              <!--              <template-->
              <!--                v-else-if="-->
              <!--                  item.keyId === 'TwilioAccountId' ||-->
              <!--                  item.keyId === 'TwilioAuthToken' ||-->
              <!--                  item.keyId === 'TwilioServiceId'-->
              <!--                "-->
              <!--              >-->
              <!--                <md-field>-->
              <!--                  <md-input type="password" v-model="item.keyValue" tabenable="yes"></md-input>-->
              <!--                </md-field>-->
              <!--              </template>-->
              <template v-else-if="item.keyId === 'Disclaimer'">
                <md-field class="md-textarea">
                  <md-textarea v-model="item.keyValue" tabenable="yes"></md-textarea>
                </md-field>
              </template>
              <template v-else>
                <md-field>
                  <md-input v-model="item.keyValue" tabenable="yes"></md-input>
                </md-field>
              </template>
            </md-table-cell>
            <md-table-cell md-label="Helper text">{{ item.description }}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </lims-block>
    <lims-block>
      <h4 class="title" slot="blockTitle">{{ $t('pages/GlobalVariables/sessionData/blockTitle') }}</h4>
      <div slot="blockContent">
        <p>{{ $t('pages/GlobalVariables/sessionData/text') }}</p>
        <div class="md-layout lims-form-row">
          <div class="md-layout-item md-size-20 md-small-size-100 datepicker-field">
            <label>{{ $t('pages/GlobalVariables/sessionData/fromDate') }}</label>
            <date-picker
              slot="field"
              v-model="formData.sessionData.fromDate"
              format="DD/MM/YYYY"
              tabenable="yes"
            ></date-picker>
          </div>
          <div class="md-layout-item md-size-20 md-small-size-100 datepicker-field">
            <label>{{ $t('pages/GlobalVariables/sessionData/toDate') }}</label>
            <date-picker
              slot="field"
              v-model="formData.sessionData.toDate"
              format="DD/MM/YYYY"
              tabenable="yes"
            ></date-picker>
            <div v-if="isValidDateToDownload" class="form-error">{{ errMessageDate }}</div>
          </div>
          <div class="md-layout-item md-size-25 md-small-size-100">
            <md-button
              @click="downloadSession()"
              class="md-button downloadSessionBtn md-primary lims-form-button md-theme-default"
              :class="
                !(formData.sessionData.fromDate && formData.sessionData.toDate) || isValidDateToDownload
                  ? 'disabled'
                  : ''
              "
            >
              {{ $t('pages/GlobalVariables/sessionData/button.downloadSession') }}
            </md-button>
          </div>
        </div>
      </div>
    </lims-block>
    <!-- Begin action form -->
    <div class="md-layout lims-form-row">
      <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
        <LimsFormCancel :redirectUrl="'/'" />
        <md-button @click="onSave()" class="md-button md-primary lims-form-button md-theme-default">
          {{ $t('global/button/button.save') }}
        </md-button>
      </div>
    </div>
  </form>
</template>
<script>
import { FormMixins, UnSaveChangesMixins, TabMixins } from '@/core/mixins';
import countryService from '@/services/country.service';
import GlobalVariablesService from '@/services/globalVariables.service';
import { APP_ROUTES, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { DropdownService } from '@/services';
import { startDay, endDay } from '@/core/helpers';

export default {
  mixins: [UnSaveChangesMixins, FormMixins, TabMixins],
  created() {
    this.fetchData();
  },
  data() {
    return {
      formData: {
        variables: {},
        sessionData: {
          fromDate: null,
          toDate: null,
        },
      },
      countries: [],
      timeZoneList: [],
      isValidDateToDownload: false,
      errMessageDate: '',

      variables: [],
    };
  },
  watch: {
    'formData.sessionData': {
      handler(val) {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        this.checkValidDateToDownload(val.fromDate, val.toDate);
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.timeZoneList = await this.getDropdownByShortName(DROPDOWN_SHORT_NAME.TIME_ZONE);
      this.variables = await this.loadGlobalVariables();
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },
    async loadCountries() {
      const { data } = await countryService.find();
      return data;
    },
    async getDropdownByShortName(shortName) {
      const res = await DropdownService.getDropdownByShortName(shortName);
      if (res.err) {
        return this.$alertError('Err: ' + res.error);
      }
      return res.data;
    },
    async loadGlobalVariables() {
      const { error, data } = await GlobalVariablesService.getGlobalVariables();
      if (error) {
        this.$alertError('Error: ' + error);
      } else {
        if (data) {
          data.variables.forEach((variable) => {
            if (variable.keyId === 'Country') {
              variable.keyValue = this.countries.find((e) => e.id == variable.keyValue);
            }
            if (variable.keyId === 'Timezone') {
              variable.keyValue = this.timeZoneList.find((e) => e.fieldItemId == variable.keyValue);
            }
            if (variable.keyId === 'MFARequired') {
              variable.keyValue =
                variable.keyValue === 'True' ? { label: 'Yes', value: '1' } : { label: 'No', value: '2' };
            }
          });
          return [...data.variables];
        }
      }
    },
    async downloadSession() {
      const res = await GlobalVariablesService.downloadSession(
        this.formData.sessionData.fromDate,
        this.formData.sessionData.toDate,
      );
      if (res.error) {
        return this.$alertError('Err: ' + res.error);
      } else {
        this.exportFileCSV(res, 'Session Login');
      }
    },
    exportFileCSV(response, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = filename + '.csv';
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async onSave() {
      const { error, data } = await GlobalVariablesService.updateGlobalVariables(
        this.coverGlobalVariablesToUpdate(this.variables),
      );
      if (error) {
        return this.$alertError('Err: ' + error);
      } else {
        if (data) {
          this.$resetChangeDetection();
          this.$alertSuccess(this.$t(`pages/GlobalVariables/save.success`));
          setTimeout(() => {
            this.$router.push(APP_ROUTES.DASHBOARD);
          }, 1500);
        }
      }
    },
    coverGlobalVariablesToUpdate(variables) {
      variables.forEach((variable) => {
        if (variable.keyId === 'Country') {
          variable.keyValue = variable.keyValue?.id.toString();
        }
        if (variable.keyId === 'Timezone') {
          variable.keyValue = variable.keyValue?.fieldItemId.toString();
        }
        if (variable.keyId === 'MFARequired') {
          variable.keyValue = variable.keyValue?.value === '1' ? 'True' : 'False';
        }
      });
      return variables;
    },
    checkValidDateToDownload(fromDate, toDate) {
      if (fromDate && toDate) {
        if (startDay(fromDate) <= endDay(toDate)) {
          this.isValidDateToDownload = false;
        } else {
          this.isValidDateToDownload = true;
          this.errMessageDate = this.$t('pages/GlobalVariables/sessionData/validDate');
        }
      } else {
        this.isValidDateToDownload = false;
      }
    },
  },
};
</script>

<style lang="scss">
.tbl-variables {
  .md-table-head {
    background: #d2d2d2;
    padding: 10px 8px;
    .md-table-head-label {
      font-weight: bold;
    }
  }
  tr:nth-child(even) {
    background: #f9f9f9;
  }
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
  .v-select,
  .md-input {
    margin: 0 !important;
  }
  .md-field.md-has-password {
    input {
      padding-right: 45px;
    }
    .md-toggle-password {
      right: 5px;
      top: 5px;
    }
  }
}
.downloadSessionBtn {
  margin-top: 36px;
  height: 36px;
}
.globalVariables-form .md-has-textarea .md-textarea {
  border: 1px solid rgba(60, 60, 60, 0.26);
  padding: 10px !important;
}
</style>
